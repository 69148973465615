<template>
  <div class="locationTargetList">
    <div v-if="isLoading" class="skeletonContainer">
      <div class="scroll-container">
        <b-skeleton width="80%" :animated="true"></b-skeleton>
        <b-skeleton width="40%" :animated="true"></b-skeleton>
        <b-skeleton width="20%" :animated="true"></b-skeleton>
        <b-skeleton width="80%" :animated="true"></b-skeleton>
        <b-skeleton width="60%" :animated="true"></b-skeleton>
        <b-skeleton width="40%" :animated="true"></b-skeleton>
        <b-skeleton :animated="true"></b-skeleton>
      </div>
    </div>
    <div v-if="!isLoading">
      <div v-if="taskType === 'Routes'" class="targetListHeader">
        <div class="titleContainer">
<!--          <h4 class="header-title">{{ $t("monitoring.targets") }}</h4>-->
          <div class="border-checkbox-section">
            <div class="border-checkbox-group border-checkbox-group-primary">
              <input type="checkbox" class="border-checkbox" v-model="hideInactiveTask" id="showOnlyActiveTask"/>
              <!-- <label v-if="hideInactiveTask" class="border-checkbox-label" for="showOnlyActiveTask">{{$t('monitoringLocation.showInactiveTask')}}</label> -->
              <label class="border-checkbox-label" for="showOnlyActiveTask">{{$t('monitoringLocation.hideInactiveTask')}}</label>
            </div>
          </div>


        </div>
        <div class="d-flex">
          <location-task-create
              @updateList="getTargets"
          />
        </div>

      </div>


      <div class="targetList" :class="{visitTargetList: taskType === 'visit'}" >
        <div class="m-b-10">
            <div class="filter-users" style="height: 100%">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  class="search-filter-monitoring"
              ></b-form-input>
            </div>
        </div>
        <b-table
            show-empty
            sticky-header
            :items="filteredTargets"
            :filter="filter"
            :filter-function="filterTable"
            :fields="fields"
            :busy="isLoading"
            :tbody-tr-class="rowClass"
            small
        >
          <template #empty="scope">{{ $t('monitoringLocation.noTargets') }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(alias)="row">
            <div class="border-checkbox-section">
              <div class="border-checkbox-group border-checkbox-group-primary" style="display:flex;">
                <input class="border-checkbox" type="checkbox" 
                  :id="`target`+row.item.id" 
                  :disabled="!selectedTargets.includes(row.item.target_id) && selectedTargets.length > 4" 
                  :value="row.item.target_id" 
                  v-model="selectedTargets">
                <label class="border-checkbox-label d-flex" style="margin-right: 0" :for="`target`+row.item.id">
                  <span v-if="row.value.length <= 20">{{row.value}}</span>
                  <span v-else :class="{'hideText': row.value.length > 20}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true  }">{{row.value}}</span>
                  <i class="feather icon-file-text f-16 m-l-10" v-if="row.item.note" :content="`<span class='f-w-700'>${$t('monitoringLocation.note')}</span>`+': '+row.item.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                  <div :class="getSelectedTargetIndex(row.item) " class="circle m-l-10"></div>
                </label>
              </div>
            </div>
          </template>

          <template #cell(active_task.started_at)="row">
            <div v-if="row.item.active" >{{moment(row.item.active_task.started_at, 'X').format('DD.MM.YYYY, HH:mm')}}
            </div>
          </template>

          <template #cell(active_task.expires_at)="row">
            <div v-if="row.item.active" >{{moment(row.item.active_task.expires_at, 'X').format('DD.MM.YYYY, HH:mm')}}
            </div>
          </template>






          <template #cell(active)="row">
            <div style="display: flex;">
              <div style="display: flex;justify-content: flex-start; align-items: center">
                <i class="feather icon-eye f-16" v-if="row.item.active"  :content="$t('monitoringLocation.status_tooltip_active')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                <i class="feather icon-eye-off f-16" v-else :content="$t('monitoringLocation.status_tooltip_disabled')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
              </div>
              <tippy class="d-flex justify-content-center align-items-center iconContainer" 
                v-if="row.item.target.locations.length" 
                placement="top" allowHTML
                interactive
                :animate-fill="false"
                arrow>
                <template v-slot:trigger><i class="feather icon-info f-16"></i></template>
                <div>
                  <div>
                    {{ $t('monitoringLocation.info_tooltip_exist') }}
                  </div>
                  <div v-if="row.item.active_task && row.item.active_task.interval">{{ `${$t('monitoringLocation.observation_interval_tooltip')}: ${$t('intervals.'+row.item.active_task.interval)}` }}</div>
                </div>
              </tippy>
              <tippy v-else class="d-flex justify-content-center align-items-center iconContainer" 
                placement="top" allowHTML
                interactive
                :animate-fill="false"
                arrow>
                <template v-slot:trigger><i class="feather icon-info crossed  f-16"></i></template>
                <div>
                  <div>
                    {{ $t('monitoringLocation.info_tooltip_not_exist') }}
                  </div>
                  <div v-if="row.item.active_task && row.item.active_task.interval">{{ `${$t('monitoringLocation.observation_interval_tooltip')}: ${$t('intervals.'+row.item.active_task.interval)}` }}</div>
                </div>
              </tippy>
              
            </div>
          </template>

          <template #cell(1)="row">
            <div style="display: flex; justify-content: center;">

              <button class="action-btn-target" :disabled="isUndefined(paramServices[row.item.target.param])" v-if="!row.item.active" @click="setSelectedTargets(row.item)" v-b-modal="'create-modal'+row.item.id"  style="display: flex;align-items: center;cursor: pointer">
                <i class="feather icon-play f-w-600 f-16 playColor" v-if="!isUndefined(paramServices[row.item.target.param])" :content="$t('monitoring.start_monitoring')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                <i v-else class="feather icon-play f-w-600 f-16 playColor"></i>
              </button>

              <div style="width: 28px;" v-if="!row.item.active"></div>

              <button class="action-btn-target" v-if="row.item.active"  @click="stopMonitoringTask(row.item.active_task.id)" style="display: flex;align-items: center;cursor: pointer">
                <i class="feather icon-pause f-w-600 f-16 text-c-red" :content="$t('monitoring.stop_monitoring')" v-tippy="{ placement : 'top',  arrow: true }"></i>
              </button>
              <button class="action-btn-target d-flex align-items-center cursor-pointer" 
                 v-b-modal="'update-modal'+row.item.id" 
                 v-if="row.item.active && timeLeftCounter(row.item.active_task.expires_at) <= 2880"
                @click="updateModalInitActions(row.item.active_task.expires_at); setSelectedTargets(row.item)" >
<!--              <button class="action-btn-target"  v-b-modal="'update-modal'+row.item.id" @click="updateModalInitActions(row.item.active_task.expires_at)"   style="display: flex;align-items: center;cursor: pointer">-->
                <i class="feather icon-edit f-w-600 f-16 editIconColor" :content="$t('monitoring.edit_target')" v-tippy="{ placement : 'top',  arrow: true }"></i>
              </button>

              <button class="action-btn-target" v-if="row.item.active && timeLeftCounter(row.item.active_task.expires_at) > 2880"  style="display: flex;align-items: center;cursor: pointer">
                <i class="feather icon-edit f-w-600 f-16 " style="color: rgba(33,37,41,0.7)" :content="$t('monitoringLocation.disable_update_tooltip')" v-tippy="{ placement : 'top',  arrow: true }"></i>
              </button>

            </div>



            <b-modal  :id="'create-modal'+row.item.id" ref="create-modal"   centered hide-footer >

              <template #modal-title>
                <span v-if="row.item.alias.length <= 20">{{row.item.alias +': '+ $t('monitoringLocation.play_modal_title')}} </span>
                <span v-else  style="display: flex">
                      <span  :class="{'hideText': row.item.alias.length > 20}" :content="row.item.alias" v-tippy="{ placement : 'top',  arrow: true  }">{{row.item.alias}}</span>
                      <span>{{': '+ $t('monitoringLocation.play_modal_title')}}</span>
                </span>
              </template>
                <div class="dialog-content m-b-15">
                  <span class="">
                    <span>{{$t('monitoringLocation.observation_period')}}:</span>
                    <b-form-select v-model="period" :options="periodList" style="margin-left: 8px; margin-right: 8px"></b-form-select>
<!--                    <span>{{$tc('monitoring.continue_day', period)}}</span>-->
                  </span>
                  <p>
                    <span>{{$t('monitoringLocation.observation_interval_tooltip')}}:</span>
                    <task-create-interval-select
                      :item="row.item"
                      @setInterval="setInterval" />
                  </p>
                  <p v-if="period" >{{ 
                    $t('monitoringLocation.estimated_price') }}: {{ calculatedPrice }} {{ $t('servicesSelect.c') }}<br><span 
                      v-if="calculatedPrice > user.account.balance && !user.account.unlimited" class="text-warning">{{ $t('monitoringLocation.over_balance') }}</span></p>
                </div>
                <div class="d-grid">
                  <b-button type="submit" variant="primary" @click="startMonitoring(row.item.id)" :disabled="!period" class="btn m-b-10">{{ $t('ui.Ok') }}
                    <span v-if="isLoading">
                      <b-spinner small></b-spinner>
                    </span>
                  </b-button>
                  <b-button type="button" variant="secondary"
                            @click="hideStartModal" class="btn">{{ $t('user.cancel') }}
                  </b-button>
                </div>
            </b-modal>



            <b-modal  :id="'update-modal'+row.item.id" ref="update-modal" centered hide-footer>
              <template #modal-title>
                <span v-if="row.item.alias.length <= 20">{{row.item.alias +': '+ $t('monitoringLocation.update_modal_title')}} </span>
                <span v-else  style="display: flex">
                      <span  :class="{'hideText': row.item.alias.length > 20}" :content="row.item.alias" v-tippy="{ placement : 'top',  arrow: true  }">{{row.item.alias}}</span>
                      <span>{{': '+ $t('monitoringLocation.update_modal_title')}}</span>
                </span>
              </template>
                <div class="dialog-content m-b-15">
                  <div v-if="row.item.active" style="white-space: nowrap">
                    {{$t('monitoringLocation.start_time') +': '+ moment(row.item.active_task.started_at, 'X').format('DD.MM.YYYY, HH:mm')}}
                  </div>

                  <span class="">
                    <span>{{$t('monitoringLocation.prolong') +': '}}</span>
                    <b-form-select v-model="period" :options="periodList" style="margin-left: 8px; margin-right: 8px"></b-form-select>
<!--                    <span>{{$tc('monitoring.continue_day', period)}}</span>-->
                  </span>

                  <div v-if="row.item.active" style="white-space: nowrap">
                    {{$t('monitoringLocation.stop_date') +': '+ moment(finalTime, 'X').format('DD.MM.YYYY, HH:mm')}}
                  </div>
                  <p v-if="period">{{ 
                    $t('monitoringLocation.estimated_price') }}: {{ calculatedPrice }} {{ $t('servicesSelect.c') }}<br><span 
                      v-if="calculatedPrice > user.account.balance && !user.account.unlimited" class="text-warning">{{ $t('monitoringLocation.over_balance') }}</span></p>
                </div>
                <div class="d-grid">
                  <b-button type="submit" variant="primary" @click="updateTarget(row.item.active_task.id)" :disabled="!period" class="btn m-b-10">{{ $t('ui.Ok') }}
                    <span v-if="isLoading">
                      <b-spinner small></b-spinner>
                    </span>
                  </b-button>
                  <b-button type="button" variant="secondary"
                            @click="hideUpdateModal" class="btn">{{ $t('user.cancel') }}
                  </b-button>
                </div>
            </b-modal>
          </template>
        </b-table>
      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import TargetActions from "@/components/monitoring/targetActions";
import MonitoringIcons from "@/components/monitoring/monitoringIcons";
import AddTarget from "@/components/monitoring/addTarget";
import LocationTaskCreate from "@/components/monitoringLocation/locationTaskCreate";
import TaskCreatePeriodSelect from "@/components/monitoringLocation/taskCreatePeriodSelect";
import TaskCreateIntervalSelect from "@/components/monitoringLocation/taskCreateIntervalSelect";
import _ from 'underscore';

export default {
  name: "locationTargetsList",
  components: { 
    TaskCreatePeriodSelect, 
    LocationTaskCreate, 
    AddTarget, 
    MonitoringIcons, 
    TargetActions, 
    TaskCreateIntervalSelect 
  },
  props: ['taskType'],
  setup() {
    return {
      moment,
      isUndefined: _.isUndefined
    }
  },
  data(){
    return {
      isEdit: true,
      anyloading: false,
      isLoading: false,
      selectedTargets: [],
      period: 30,
      interval: 1,
      periodList: [3,7,10,14,21,30],
      modalAction: null,
      hideInactiveTask: false,
      finalTime: null,
      selectedTargetsObjects: [],
      filter: null
    }
  },
  watch: {
    selectedTargets(){
      this.$emit('targetsListChange', this.selectedTargets)
    },
    selectedTargetsObjects: {
      deep: true,
      handler(){
        this.period = 30;
        this.interval = 1;
      }
    },
    period(){
      if (typeof this.period !== 'undefined'){
        this.finalTime = moment(this.finalTime, "X").add(this.period, "days")
      }
    },
  },
  computed: {
    ...mapGetters('monitoring', ['targets', 'sources', 'currentMonitoringCount']),
    ...mapGetters('locationMonitoring', ['prices']),
    ...mapGetters("auth", ["user"]),
    ...mapGetters({
      monitoringServices: "serviceMonitoringGroup/customerServices",
    }),
    filteredTargets(){
      let arr = this.targets;
      if (this.hideInactiveTask){
        arr = arr.filter(item=> item.active)
      }
      return arr
    },
    fields(){
        return  [
          {
            key: 'alias',
            label:  this.$t('monitoringLocation.target'),
            sortable: false
          },
          {
            key: 'active_task.started_at',
            label:  this.$t('monitoringLocation.start_time'),
            sortable: false
          },
          {
            key: 'active_task.expires_at',
            label:  this.$t('monitoringLocation.end_time'),
            sortable: false
          },
          {
            key: '0',
            label:  '',
            sortable: false
          },
          {
            key: 'active',
            label:  this.$t('monitoringLocation.active_status'),
            sortable: true,
            'class': 'sortableColumnheader'
          },
          {
            key: '1',
            label:  this.$t('monitoring.table_action'),
            sortable: false,
            'class': 'actionsBlock',
          },
        ]
    },
    servicesPrices(){
      const serviceObj = {};
      this.prices.services_monitoring_rates.forEach((item) => {
        serviceObj[item.source] = item.price
      });
      return serviceObj;
    },
    defaultPrice(){
      const locationPrice = this.prices.monitoring_rates.find((item) => item.type === 'location');
      return !_.isUndefined(locationPrice) ? locationPrice.price : 0;
    },
    selectedServicesPrices(){
      let price = 0;
      this.selectedServices.forEach((item) => {
        const servicePrice = !_.isUndefined(this.servicesPrices[item]) ? this.servicesPrices[item] : this.defaultPrice;
        price += servicePrice;
      })
      return price;
    },
    paramServices(){
      let params = [];
      const paramObj = {};
      this.monitoringServices.forEach((service) => {
        if (service.monitoring_types.includes("location")){
          params = _.union(params, service.monitoring_params)
        }
      });
      params.forEach((param) => {
        paramObj[param] = this.monitoringServices.filter((service) => service.monitoring_params.includes(param) && service.monitoring_types.includes('location')).map((service) => service.name)
      });
      return paramObj;
    },
    calculatedPrice(){
      let period = this.period;
      if(typeof this.finalTime === 'number') {
        period = moment(this.finalTime, 'X').diff(moment(), 'days');
      }
      const interval = this.selectedTargetsObjects.map(target => target.active_task ? target.active_task.interval: this.interval).flat();
      return this.selectedServicesPrices * (1440/interval) * period;
    },
    selectedServices() {
      if(this.selectedTargetsObjects.length === 0){
        return []
      }
      return this.selectedTargetsObjects.map(target => this.paramServices[target.target.param]).flat()
    },
  },
  methods: {
    filterTable(row, filter){
      if (row.alias.toLowerCase().indexOf(filter.toLowerCase()) > -1 || this.selectedTargets.includes(row.target_id)) {
        return true;
      } else {
        return false;
      }
    },
    timeLeftCounter(val){
      let left;
      let eventTime = moment(val, 'X')
      let now = moment();
      left = eventTime.diff(now, 'minute')
      return left
    },
    setInterval(val){
      this.interval = val
    },
    setSelectedTargets(val){
      this.selectedTargetsObjects = [val]
    },
    setPeriod(val){
      this.period = val
    },
    hideUpdateModal(){
      this.$refs['update-modal'].hide();
    },
    hideStartModal(){
      this.$refs['create-modal'].hide();
    },

    updateModalInitActions(item){
      this.period = undefined;
      this.finalTime = item
    },

    startMonitoring(id) {
      this.$refs['create-modal'].hide();
      this.isLoading = true
      let payload = {
        id: id
      }
      if (this.period !== 0){
        payload.period = parseInt(this.period)
      }
      payload.interval = this.interval
      this.$store.dispatch('locationMonitoring/createMonitoringTask', payload).then(()=>{
        this.isLoading = false
        this.getTargets()
      }).catch(()=>{
        this.isLoading = false
        this.getTargets()
      })
    },


    updateTarget(id){
      let payload = {
        id: id,
        period: this.period
      }
      this.$refs['update-modal'].hide();
      this.isLoading = true
      this.$store.dispatch('locationMonitoring/updateRouteTask', payload).then(()=>{
        this.isLoading = false
        this.getTargets()
      }).catch(()=>{
        this.getTargets()
        this.isLoading = false
      })
    },
    stopMonitoringTask(id) {
      this.isLoading = true
      this.$store.dispatch('locationMonitoring/stopRouteTask', id).then(()=>{
        this.isLoading = false
        this.getTargets()
      }).catch(()=>{
        this.getTargets()
        this.isLoading = false
      })
    },
    rowClass(item){
      if (typeof item !== 'undefined' && this.taskType === 'Routes'){
        let index = this.selectedTargets.findIndex((element) => item.target.id === element)
        if (index !== -1){
          // return 'color-'+ index
          return 'colored'
        }
      }
    },

    getSelectedTargetIndex(item){
      if (this.taskType === 'Routes') {
        let index = this.selectedTargets.findIndex((element) => item.target.id === element)
        if (index !== -1) {
          return 'color-' + index
        }
      }
    },

    getTargets() {
      let payload = {
        filters: {
          type: 'location'
        }
      }
      this.isLoading = true
      this.$store.dispatch('monitoring/getTargets', payload).then(() => {
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false;
        this.isError = true;
        this.errorText = err.response.data.message ? err.response.data.message : err.response.data.error
      })
    },
    anyLoading(val) {
      this.anyloading = val;
    }
  },
  mounted() {
    this.getTargets();
  }
}
</script>

<style lang="scss">
.locationTargetList{
  .sortableColumnheader{
    div{
      margin-right: 8px;
    }
  }
  .hideText {
    width: 5vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.iconContainer {
  position: relative;
  .crossed:after {
    content: "";
    position: absolute;
    left: 7px;
    top: -3px;
    right: 0;
    bottom: 0px;
    width: 1px;
    height: 22px;
    background-color: rgb(33, 37, 41);
    background-size: cover;
    transform: rotate(135deg);
  }
}

.circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 2px;
  //height: 16px;
  //width: 16px;
  //border-radius: 50%;
  margin-right: 4px;
}

.colored {
  .color-tag-0 {
    background-color: rgba(0, 123, 255, 0.6);
  }

  .color-tag-1 {
    background-color: rgba(40, 167, 69, 0.6);
  }

  .color-tag-2 {
    background-color: rgba(23, 162, 184, 0.6);
  }

  .color-tag-3 {
    background-color: rgba(220, 53, 69, 0.6);
  }

  .color-tag-4 {
    background-color: rgba(55, 71, 79, 0.6);
  }

}

.skeletonContainer {
  padding: 8px;
}

.targetListHeader{
  display: flex;
  justify-content: space-between;
  padding: 8px 14px 8px 14px;
  .titleContainer{
    display: flex;
    align-items: center;
  }
}
  .visitTargetList{
    //height: 368px;
  }
.table.table-sm td{
  padding: 6px !important;
}
.targetList{
  padding: 8px;
}

.locationTargetList {
  .border-checkbox-section .border-checkbox-group .border-checkbox-label {
    white-space: nowrap;
  }
}

</style>