<template>
    <div>
        <form @submit.prevent="createArea">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">{{ $t('monitoringLocation.title') }}</label>
                        <input class="form-control" :class="{'is-invalid': errorTitle}" id="title" v-model="payload.title">
                        <span class="error text-danger" v-if="errorTitle">{{ $t('monitoringLocation.title_taken') }}</span>
                    </div>
                    <div class="form-group">
                        <label for="note">{{ $t('monitoringLocation.noteArea') }}</label>
                        <textarea class="form-control" id="note" rows="3" v-model="payload.note"
                            ></textarea>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('monitoringLocation.targets') }}</label>
                        <multiselect v-model="targetSelected" class="m-b-20 targets-select" :options="dropdownList"
                            :searchable="false" :multiple="true" placeholder=""
                            track-by="target_id" :clear-on-select="false" selectLabel="" deselectLabel="" selectedLabel=""
                            :preserve-search="true">
                            <template slot="maxElements">{{ $t('monitoring.max_targets') }}</template>
                            <template slot="noOptions">{{ $t('monitoring.no_targets') }}</template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                  <span v-if="props.option.alias">
                                         <span v-if="props.option.length <= 20">{{props.option.alias}}</span>
                                         <span v-else :class="{'hideText':props.option.alias.length > 20}" :content="props.option.alias" v-tippy="{ placement : 'top',  arrow: true  }">{{props.option.alias}}</span>
                                  </span>
                                  <span v-else>
                                   {{ $t('monitoring.' + props.option.target.param) }}: {{ props.option.target.value }}
                                  </span>
<!--                                  {{ props.option.alias ? props.option.alias : $t('monitoring.' + props.option.target.param) }}: {{ props.option.target.value }}-->
                                </div>
                            </template>
                            <template slot="tag" slot-scope="{option}">
                                <span class="multiselect__tag">
                                    <span style="display:flex;">
<!--                                      {{ option.alias ? option.alias : '' }}-->
                                       <span v-if="option.alias.length <= 20">{{option.alias}}</span>
                                       <span v-else :class="{'hideText':option.alias.length > 20}" :content="option.alias" v-tippy="{ placement : 'top',  arrow: true  }">{{option.alias}}</span>
                                      :{{ option.target.value }}</span>
                                    <i tabindex="1" class="multiselect__tag-icon" @click="removeResult(option)"></i>
                                </span>
                            </template>
                        </multiselect>
                    </div>
                    <div class="form-group">
                        <label for="area">{{ $t('monitoringLocation.areaExpiresAt') }}</label>
                        <date-picker v-model="payload.expires_at" type="datetime"
                            :placeholder="$t('settings.datePlaceholder')" format="DD.MM.YYYY HH:mm" :editable="false"
                            :lang="lang[$i18n.locale]" :disabled-date="disabledBeforeToday"></date-picker>
                    </div>
                    
                </div>
                <div class="col-md-6">
                    <h5>{{ $t('monitoringLocation.create_polygon') }}</h5>
                    <div id="create-area-map" style="width: 100%; height: 400px;"></div>
                </div>
            </div>
            <footer class="d-grid gap-2 m-t-15">
                <b-button type="submit"  variant="primary" :disabled="this.$v.$invalid" >{{ $t('ui.Ok') }}
                    <span v-if="isLoading">
                    <b-spinner small></b-spinner>
                    </span>
                </b-button>
                <b-button type="button" variant="secondary"
                            @click="closeDialog">{{ $t('user.cancel') }}
                </b-button>
            </footer>
        </form>
    </div>
</template>
<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import drawLocales from 'leaflet-draw-locales';
import { parse, stringify } from 'wkt';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import { mapGetters } from "vuex";
import moment from 'moment';
import { validationMixin } from 'vuelidate'
import {
  required
} from "vuelidate/lib/validators";

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import {EventBus} from "@/eventBus";
import { isString, isBoolean } from 'underscore';

export default {
    name: "createArea",
    components: {
        DatePicker,
        Multiselect
    },
    setup() {
        return {
            lang: {
                ua: {
                    formatLocale: {
                        months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
                        monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
                        weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
                        weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                        weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                        firstDayOfWeek: 1,
                        firstWeekContainsDate: 7
                    }
                },
                ru: {
                    formatLocale: {
                        months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
                        monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
                        weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
                        weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                        weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                        firstDayOfWeek: 1,
                        firstWeekContainsDate: 1
                    }
                },
                en: {
                    formatLocale: {
                        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                        firstDayOfWeek: 0,
                        firstWeekContainsDate: 1
                    }
                }
            }
        }
    },
    mixins: [validationMixin],
    data() {
        return {
            payload: {
                area: null,
                expires_at: null,
                title: null,
                note: null,
                target_ids: [],
                alert_status: true
            },
            map: null,
            center: [50.4501, 30.5234],
            zoom: 5,
            startZoom: 3,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            area: null,
            createAreaModal: false,
            drawnItems: null,
            searchItems: null,
            targetSelected: [],
            isLoading: false,
            errorTitle: null
        }
    },
    validations() {
        return {
            payload: {
                title: { required },
                area: { required }
            }
        }
    },
    methods: {
        createArea() {
            this.isLoading = true;
            this.errorTitle = null;
            this.$store.dispatch('locationMonitoring/createArea', this.computedPayload).then(() => {
                this.isLoading = false;
                EventBus.$emit('updateSubscribe');
                this.$emit('success');
                this.$emit('close');
            }).catch((res) => {
                if(res.status === 422) {
                    if(res.data.message == 'The title has already been taken.') {
                        this.errorTitle = res.data.message;
                    }
                }
                this.isLoading = false;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        async initMap() {
            const locale = drawLocales(this.locale)
            L.drawLocal = locale;
            L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

            this.map = await L.map('create-area-map', {
                zoomControl: false,
                attributionControl: false,
                center: this.center,
                zoom: this.startZoom
            });

            L.tileLayer(this.url, { maxZoom: 20, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
            L.control.zoom({
                zoomInTitle: this.$t('geo.zoomIn'),
                zoomOutTitle: this.$t('geo.zoomOut')
            }).addTo(this.map)

            var southWest = L.latLng(-89.98155760646617, -180),
                northEast = L.latLng(89.99346179538875, 180);
            var bounds = L.latLngBounds(southWest, northEast);

            this.map.setMaxBounds(bounds);
            this.map.on('drag', () => {
                this.map.panInsideBounds(bounds, { animate: false });
            });

            this.drawnItems = new L.FeatureGroup().addTo(this.map);
            this.searchItems = new L.FeatureGroup().addTo(this.map);
            const drawControl = new L.Control.Draw({
                edit: {
                    featureGroup: this.searchItems,
                    edit: false,
                    remove: false
                },
                draw: {
                    circle: false,
                    polygon: {
                        icon: new L.DivIcon({
                            iconSize: new L.Point(8, 8),
                        }),
                        showArea: true,
                    },
                    marker: false,
                    polyline: false,
                    rectangle: false,
                    circlemarker: false
                }
            });
            const provider = new OpenStreetMapProvider();
            const searchControl = new GeoSearchControl({
                provider: provider,
                style: 'button',
                showMarker: false,
                searchLabel: this.$t('geo.enter_address'),
                notFoundMessage: this.$t('errors.not_found'),
                // marker: {
                //     icon: new CustomMarker(),
                //     draggable: false,
                // },
                autoComplete: true,
                autoCompleteDelay: 250
            });
            this.map.addControl(drawControl);
            this.map.addControl(searchControl);
            this.map.on('draw:created', this.drawCreated);
            this.map.on('draw:drawstart', () => {
                this.drawing = true;
                this.searchItems.clearLayers();
            });
        },
        removeResult(tag) {
            const ind = this.targetSelected.findIndex(v => v.target_id === tag.target_id);
            if (ind >= 0) {
                this.targetSelected.splice(
                    ind,
                    1
                );
            }
        },
        createDrawing() {

        },
        updateMap() {
            if(this.map) {
                this.map.remove();
            }
            this.initMap();
        },
        drawCreated(event) {
            this.drawing = false;
            const layer = event.layer;
            if (layer.getTooltip()) {
                layer.unbindTooltip();
            }
            const geoJSON = layer.toGeoJSON();
            this.payload.area = stringify(geoJSON);
            this.createAreaModal = true;

            const figure = this.searchItems.addLayer(layer);
            figure.on('click', this.selectLayout);
            // this.setGeoJSON();
            // this.toggleDisableClasses();
        },
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date < today;
        },
        closeDialog() {
            this.$emit('close')
        },
    },
    computed: {
        computedPayload() {
            const payload = {
                title: undefined,
                note: undefined,
                area: undefined,
                expires_at: undefined,
                target_ids: undefined,
                alert_status: true
            };
            Object.keys(payload).forEach(key => {
                if(key !== 'note') {
                    if (this.payload[key] !== null || isBoolean(this.payload[key])) {
                        if (key === 'expires_at') {
                            payload[key] = parseInt(moment(this.payload[key], 'DD.MM.YYYY, HH:mm').format('X'));
                        } else {
                            payload[key] = this.payload[key];
                        }
                    }
                } else {
                    const note = isString(this.payload[key]) ? this.payload[key].trim() : this.payload[key];
                    payload[key] = isString(note) && note.length ? note : undefined;
                }
            });
            const finalPayload = JSON.parse(JSON.stringify(payload));           
            return finalPayload;
        },
        ...mapGetters('monitoring', ['targets', 'colors']),
        dropdownList() {
            let list = [];
            this.targets.forEach(target => {
                list.push(target)
            })
            return list;
        },
        locale() {
            return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
        },
    },
    watch: {
        targetSelected(value) {
            this.payload.target_ids = value.map(item => item.target_id)
        }
    },
}
</script>