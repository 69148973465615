<template>
  <div class="pagination-comp">
    <div class="card paginationCard" v-if="typeof data.count !== 'undefined' && data.total > data.count">
        <div class="block paginationBlock">
        <span class="p-size">
          <span v-html="$tc('geo.total_find', data.total, {n: data.total} )"></span>
          <span v-if="data.count > 1">
          <span>, </span>
          <span  v-html="$tc('result.total_show', data.count , {n : data.count})"></span>
          </span>
          <span v-else-if="data.count === 1">
          <span>, </span>
          <span  v-html="$tc('result.one_show', data.count , {n : data.count})"></span>
          </span>
        </span>
          <button class="btn btn-primary" @click="paginate">
            <b-spinner small v-if="isPaginate"></b-spinner>
            <span style="text-transform: none">{{ $t('geo.show_more') }}</span>
          </button>
        </div>
      </div>
      <div v-else class="paceHolder" style="height: 55px"></div>
  </div>
</template>
<script>
export default {
    name: 'pagination',
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        isPaginate: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        paginate() {
            this.$emit('paginate');
        }
    }
}
</script>
<style lang="scss">
.pagination-comp {
  .paginationCard {
      padding: 8px 14px;
      margin-bottom: 5px;
      .paginationBlock{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
}
</style>