<template>
    <div class="card monitoring-location-page">
      <b-tabs content-class="mt-3" fill  v-model="tabIndex">
        <b-tab active>
          <template #title> <img class="tab-img" src="/files/assets/icon/custom-svg/crosshair.svg" > {{ $t('monitoringLocation.targets') }}</template>
          <monitoring-location-targets></monitoring-location-targets>
        </b-tab>
        <b-tab>
          <template #title> <i class="feather icon-share-2 text-black m-r-10"></i> {{ $t('monitoringLocation.routes') }}</template>
          <routes ref="routes"></routes>
        </b-tab>
        <b-tab>
          <template #title><img class="tab-img" src="/files/assets/icon/custom-svg/alert-triangle.svg" > {{ $t('monitoringLocation.alarmZones') }}</template>
          <alarm-zones ref="areas"></alarm-zones>
        </b-tab>
        <b-tab>
          <template #title><img class="tab-img" src="/files/assets/icon/custom-svg/intersect.svg" > {{ $t('monitoringLocation.intersections') }}</template>
          <intersections ref="intersections"></intersections>
        </b-tab>
        <b-tab>
          <template #title> <img class="tab-img" src="/files/assets/icon/custom-svg/countries.svg" alt=""> {{ $t('monitoringLocation.visitPlaces') }}</template>
          <visit-places ref="visitPlaces"></visit-places>
        </b-tab>
      </b-tabs>
    </div>
</template>

<script>
import Targets from "@/components/monitoring/targets";
import MonitoringFilters from "@/components/monitoring/monitoringFilters";
import {mapGetters} from "vuex";
import Routes from "@/components/monitoringLocation/Routes";
import AlarmZones from "@/components/monitoringLocation/alarmZones";
import Intersections from "@/components/monitoringLocation/intersections";
import VisitPlaces from "@/components/monitoringLocation/visitPlaces";
import MonitoringLocationTargets from "@/components/monitoringLocation/MonitoringLocationTargets";
import {EventBus} from "@/eventBus";

export default {
  name: "MonitoringLocation",
  components: {
    MonitoringLocationTargets,
    VisitPlaces, 
    Intersections, 
    AlarmZones, 
    Routes, 
    MonitoringFilters, 
    Targets
  },
  data() {
    return {
      targetId: null,
      searchInfo: [],
      isLoading: false,
      isError: false,
      errorText: "",
      tabIndex: 0,
    }
  },
  methods: {
    getPrices() {
      this.$store.dispatch('locationMonitoring/getPrices')
    },
    notificationMonitoringAction(e) {
      if(this.notificationMonitoring) {
        this.$nextTick(() => {
          if(this.notificationMonitoring.type === 'area') {
            this.tabIndex = 2;
            this.$refs.areas.preventFilter();
            // this.$store.dispatch('locationMonitoring/setNotificationMonitoring', null);
          } else if(this.notificationMonitoring.type === 'crossTracking') {
            this.tabIndex = 3;
            this.$refs.intersections.preventFilter();
            // this.$store.dispatch('locationMonitoring/setNotificationMonitoring', null);
          }
        })
      }
    },
    getMonitoringServices() {
      this.$store.dispatch('serviceMonitoringGroup/getCustomerServices')
    }
  },
  mounted() {
    if (!this.isLocationMonitoring) {
      this.$router.push({name: 'Home'})
    }
    this.notificationMonitoringAction();
    this.getPrices();
    this.getMonitoringServices();
  },
  created() {
    EventBus.$on('notificationMonitoringAction', this.notificationMonitoringAction);
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('locationMonitoring', ['notificationMonitoring']),
    ...mapGetters({
      monitoringServices: "serviceMonitoringGroup/customerServices",
    }),
    isLocationMonitoring() {
      return this.monitoringServices.some((service) => service.monitoring_types.includes("location"));
    },
  },
  watch: {
    tabIndex(val) {
      if(val === 1) {
        this.$refs.routes.updateMap();
      } else if(val === 2) {
        this.$refs.areas.$refs.mapZones.updateMap();
      } else if(val === 3) {
        this.$refs.intersections.updateMap();
      } else if(val === 4) {
        this.$refs.visitPlaces.$refs.mapPlaces.updateMap();
      }
    }
  }
}
</script>
<style lang="scss">
.tab-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.nav-item{
  .active {
    font-weight: 600;
  }
}
.rangeSelectBlock{
  .daterangepicker {
    top: -315px !important;
    &::before{
      top: 301px !important;
      transform: rotate(180deg) !important;
    }
    &::after{
      top: 301px !important;
      transform: rotate(180deg) !important;
    }
  }
}
.monitoring-location-page {
  .table {
    th, td, p {
      @media (min-width: 768px) and (max-width: 1300px) {
        font-size: 12px;
      }
    }
    th, td {
      @media (min-width: 768px) and (max-width: 1300px) {
        padding: 4px !important;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .rangeSelectBlockTitle h4 {
    font-size: 14px;
  }
  .reportrange-text {
    font-size: 12px;
  }
}

.action-btn-target:disabled {
  cursor: default;
  .playColor {
    color: #bbbbbb; 
  }
} 
.p-size {
  font-size: 14px;
}


</style>