<template>
    <div id="mapPlaces"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import drawLocales from 'leaflet-draw-locales';
import {parse} from 'wkt';

export default {
    data() {
        return {
            map: null,
            center: [50.4501, 30.5234],
            zoom: 5,
            startZoom: 3,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            drawnItems: null,
        };
    },
    props: ['selectedPlaces'],
    methods: {
        async initMap() {
            const locale = drawLocales(this.locale)
            L.drawLocal = locale;
            L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

            this.map = await L.map('mapPlaces', {
                zoomControl: false,
                attributionControl: false,
                center: this.center,
                zoom: this.startZoom
            });

            L.tileLayer(this.url, { maxZoom: 20, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
            L.control.zoom({
                zoomInTitle: this.$t('geo.zoomIn'),
                zoomOutTitle: this.$t('geo.zoomOut')
            }).addTo(this.map)

            var southWest = L.latLng(-89.98155760646617, -180),
                northEast = L.latLng(89.99346179538875, 180);
            var bounds = L.latLngBounds(southWest, northEast);

            this.map.setMaxBounds(bounds);
            this.map.on('drag', () => {
                this.map.panInsideBounds(bounds, { animate: false });
            });

            this.drawnItems = new L.FeatureGroup().addTo(this.map);
        },
        setPlaces() {
            this.drawnItems.clearLayers();
            this.selectedPlaces.forEach(place => {
                const polygon = parse(place.place.area);
                const layer = L.geoJSON(polygon, {
                    style: {
                        color: '#ff5370',
                        fillColor: '#ff5370',
                        fillOpacity: 0.5
                    }
                });
                this.drawnItems.addLayer(layer);
            });
            if(this.selectedPlaces.length) {
                this.map.fitBounds(this.drawnItems.getBounds());
            }
        },
        updateMap() {
            setTimeout(() => {
                this.map.invalidateSize();
            }, 100);
        }
    },
    computed: {
      locale() {
        return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
      },
    },
    mounted() {
        this.initMap();
    },
    watch: {
        selectedPlaces() {
            this.setPlaces();
        }
    }
};
</script>

<style>
#mapPlaces {
    height: 554px;
    width: 100%;
}
</style>